@import '~antd/dist/antd.less';
// @import '~antd/dist/antd.compact.less';
@primary-color: #2B78E4; // primary color for all components
@nav-bg-color: #2B78E4;
@tooltip-bg: rgba(94, 104, 116, 0.95);
@link-color: #2B78E4;
@link-hover-color: #1f5eb8;


#root {
    position: relative;
    height: 100%;
}

h1,h2,h3{
    font-weight: bold;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    -khtml-border-radius: 0px;
    border-radius: 0px;
}

::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.25);
}

::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active {
    cursor: pointer;
}

.auth-wrapper{
    position: relative;
    height: 100%;
    .auth-table{
        display: table;
        width: 100%;
        min-height: 100%;
        height: 100%;
        vertical-align: middle;
        .auth-image{            
            background: url('./../images/auth-bg.png');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            display: table-cell;
            width: 55%;
            vertical-align: middle;
            height: 100%;
        }
        .auth-contents{
            background: white;
            display: table-cell;
            width: 45%;
            vertical-align: middle;
            height: 100%;
            .auth-form-wrapper{
                width: 100%;
                max-width: 360px;
                margin: auto;
                .auth-logo{
                    margin: 15px 0;
                    margin-bottom: 40px;
                    img{
                        max-height: 55px;
                    }
                }
            }
        }
    }
}

// .ant-btn-primary{
//     color: rgba(0,0,0, 0.5);
//     &:hover{
//         color: rgba(0,0,0, 0.75);
//     }
// }

.global-header{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    background-color: #FFF;
    height: 50px;
    display: table;
    .logo{
        position: relative;
        height: 100%;
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        width: 220px;
        border-right: 1px solid rgba(0,0,0,0.05);
        img{
            max-height: 35px;
        }
    }
    .header-inner{
        display: table-cell;
        vertical-align: middle;
        padding: 0 2em;
        width: calc(100% - 220px);
        h3{
            margin: 0;
            em{
                margin-left: 15px;
                cursor: pointer;
            }
        }
    }
}
.global-content{
    margin-left: 220px;
    position: relative;
    top: 50px;
    height: calc(100% - 50px);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -ms-transition: all 0.3s;
    .toggle-sidebar{
        position: absolute;
        top: 20px;
        left: -14px;
        min-width: 32px;
        width: 32px;
        max-width: 32px;
        height: 24px;
        min-height: 24px;
        max-height: 24px;
        background-color: #FFF;
        padding: 0 !important;
        border: 1px solid #e9e9e9;
        line-height: 24px;
        // border-radius: 12px;
        opacity: 0;
        z-index: 2;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        -ms-transition: all 0.3s;
        // -webkit-box-shadow: 0px 0px 3px 0px rgba(50, 50, 50, 0.25);
        // -moz-box-shadow:    0px 0px 3px 0px rgba(50, 50, 50, 0.25);
        // box-shadow:         0px 0px 3px 0px rgba(50, 50, 50, 0.25);
        &.visible{
            opacity: 0;
        }
    }
}

.global-sidebar{
    position: fixed;
    left: 0px;
    top: 50px;
    height: calc(100% - 50px);
    width: 220px;
    box-sizing: border-box;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #FFF;
    border-right: 1px solid rgba(0,0,0,0.05);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -ms-transition: all 0.3s;
    .sidebar-contents{
        padding: 20px;
        width: 220px;
        white-space: pre-wrap;
        ul{
            list-style-type: none;
            margin: 0;
            padding: 0;
            li{
                display: block;
                margin: 5px 0;
                a{
                    display: block;
                    padding: 8px 10px;
                    text-decoration: none;
                    font-weight: 500;
                    color: #8e8c96;
                    border-radius: 4px;
                    .anticon{
                        margin-right: 10px;
                        font-size: 18px;
                    }
                    &:hover{
                        text-decoration: none;
                        color: #4c4b52;
                        // background-color: @nav-bg-color;
                        .anticon{
                            color: @nav-bg-color;                            
                        }
                    }                                        
                }
                &.selected{
                    a{
                        text-decoration: none;
                        color: rgba(#FFF, 0.95) !important;
                        background-color: @nav-bg-color;
                        .anticon{
                            color: rgba(#FFF, 0.95) !important;
                            
                        }
                    }
                }
            }
        }
        .nav-seperator{
            margin: 15px 0;
            height: 1px;
            border: 0;
            background-color: rgba(0,0,0,0.25);
        }
    }
}

body{    
    &.sidebar-closed{
        .global-sidebar{            
            width: 10px;            
        }
        .global-content{
            position: relative;
            height: 100%;
            margin-left: 20px;
        }
    }
}

.full-loader{    
    width: 100%;
    min-height: 100%;
    position: relative;    
    display: flex;
    align-items: center;
    justify-content: center;

}

.content-wrapper{
    padding: 0px;
    background-color: #F5F7FB;
    height: auto;
    min-height: 100%;
    position: relative;
    .inner-contents{
        padding: 25px 2em;
    }
}

.map-wrapper{
    display: table;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    .google-maps-wrapper{
        position: relative;
        left: 0;
        top: 0;
        width: 70%;
        display: table-cell;
        height: 100%; 
    }
    .map-sidebar{
        width: 30%;
        position: relative;
        height: 100%;           
        display: table-cell;
        vertical-align: top;
        .map-sidebar-inner{
            position: relative;
            height: 100%;
            overflow-y: auto;
            border-left: 4px solid #FFF;     
            .side-panel{
                h3{
                    background-color: #FFF;
                    border-top: 1px solid #d8d8d8;
                    border-bottom: 1px solid #d8d8d8;
                    margin: 0;
                    font-size: 14px;
                    padding: 5px 20px;
                }
                .side-panel-body{
                    padding: 20px;
                    h5{
                        font-weight: normal;
                        font-size: 10px;
                        text-transform: uppercase;
                        color: rgba(0,0,0,0.35);
                    }
                }
                .current-job{
                    font-size: 12px;
                    padding: 10px 20px;
                    background-color: #f9f9f9;
                    border-bottom: 1px dotted rgba(0,0,0,0.15);
                }
            }
        }
    }
}


.text-right{
    text-align: right;
}

.text-center{
    text-align: center;
}

.meta-nav{
    color: #888;
    em{
        color: @nav-bg-color;
        font-style: normal;
    }
    a{
        color: #888;
        margin-left: 15px;
        &:hover{
            color: @nav-bg-color;
        }
    }
}

.ant-tooltip-inner{
    font-size: 11px;
    min-height: 24px;
}

.ant-form-vertical{
    .ant-form-item-label{
        padding-bottom: 0;
        label{
            text-transform: uppercase;
            font-size: 11px;
            font-weight: 500;
            color: #485460
        }        
    }
}

.ant-toast{
    text-align: right;
    .ant-message-notice-content{        
        background-color: #485460;
        color: #FFF;
    }
}

.ant-form-item-explain,
.ant-form-item-extra{
    font-size: 11px;
}

.driver-card{
    background-color: #FFF;
    padding: 15px;
    display: table;
    margin-bottom: 16px;
    width: 100%;
    &.compact{
        padding: 0;
        .thumbnail{
            width: 40px;
        } 
        .meta-data{
            width: calc(100% - 50px);   
        }
    }
    .thumbnail{
        display: table-cell;
        width: 110px;
    }
    .meta-data{
        display: table-cell;
        width: calc(100% - 120px);
        vertical-align: top;
        h4{
            font-size: 16px;
        }
        p{
            margin: 3px 0;
            font-size: 12px;
            
        }
    }
}

.package-item{
    border: 1px solid #d8d8d8;
    padding: 10px 20px;
    margin: 10px 0;
    position: relative;
    background-color: #E9EDF2;
    .remove-pack{
        position: absolute;
        right: 0;
        top: 0;
    }
}

.jobs-table{
    width: 100%;
    border-collapse: separate;
    border-spacing:0 5px; 
    thead{
        tr{
            th{
                text-transform: uppercase;
                font-size: 12px;
                color: #222;
            }
        }
    }
    tbody{
        tr{
            padding: 10px 0;
            background: #FFF;
            td{
                padding: 20px 10px;
            }
        }
    }
}

.car-icon{
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 20px;
    height: 32px;
    user-select: none;
    transform: translate(-50%, 0%);
    &:hover {
      z-index: 1;
    }
    img{
        position: absolute;
        left: -8px;
        top: -2px;
    }
}

.address-autocomplete-wrapper{
    position: relative;
    .autocomplete-dropdown-container{
        background-color: #FFF;
        -webkit-box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.25);
        -moz-box-shadow:    0px 0px 10px 0px rgba(50, 50, 50, 0.25);
        box-shadow:         0px 0px 10px 0px rgba(50, 50, 50, 0.25);
        z-index: 99;
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        max-height: 200px;
        overflow-y: auto;
        border: 1px solid #eee;
        .suggestion-item{
            padding: 5px 10px;
            font-size: 13px;
            &:hover{
                background-color: #f2f2f2;
            }
        }
    }
}

.driver-popup{
    min-width: 300px;
}

.driver-title-bar{
    font-size: 12px;
    span{
        margin-right: 10px;
    }
    .number-plate{
        position: absolute;
        right: 5px;
        top: 8px;
    }
}
.number-plate{
    display: table;
    border-radius: 3px;
    overflow: hidden;
    .left-bar{
        background-color: #3661DD;
        color: #FFF;
        display: table-cell;
        vertical-align: middle;
        padding: 0px 3px;
        height: 100%;
        font-size: 8px;
        .circle{
            margin: 0;
            width: 8px;
            height: 8px;
            border: 1px dotted #FFF;
            display: inline-block;
            vertical-align: middle;
            border-radius: 5px;
        }
        em{
            margin-left: 2px;
            font-style: normal;
            font-weight: 500;
            vertical-align: middle;
            display: inline-block;
        }
    }
    strong{
        font-size: 10px;
        display: table-cell;
        padding: 0 3px;
        font-weight: 600;
        vertical-align: middle;
        background-color: #FFD32A;
    }
}

.wide-wrapper{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    .navigation-panel{
        position: absolute;
        left: 0;
        top: 0;
        width: 380px;
        height: 100%;
        border-right: 5px solid #FFF;
        vertical-align: top;
        .navigation-container{
            height: 100%;
            position: relative;
            overflow-y: auto;
            .item-nav{
                padding: 10px 20px;
                border-bottom: 1px solid rgba(0,0,0,0.05);
                &.selected{
                    background: rgba(0,0,0,0.05);
                }
                h4{
                    font-weight: bold;
                    margin-bottom: 0;
                }
                .item-dim{
                    font-size: 11px;
                    color: rgba(0,0,0,0.35);
                }
                .location-item{
                    font-size: 12px;
                    word-break: keep-all;
                    white-space: nowrap;
                    width: 330px;
                    overflow: hidden;
                    color: rgba(0,0,0,0.5);
                    text-overflow: ellipsis;
                }
            }
        }
    }
    .wide-content-panel{
        position: absolute;
        left: 380px;
        top: 0;
        width: calc(100% - 380px);
        vertical-align: top;
        overflow-y: auto;
        height: 100%;
        .panel-body-contents{
            .content-header{
                padding: 20px;
                h3{
                    margin: 0;
                }
            }
            .location-info{
                margin-bottom: 20px;
                font-size: 13px;
                color: rgba(0,0,0,0.65);
                h4{
                    margin: 0;
                    color: #000;
                }
            }
        }
        .content-body{
            padding: 20px;
            border-top: 1px solid #e9e9e9;
        }
    }
}

.action-bar{
    margin-top: 20px;
    text-align: center;
    button{
        margin: 0 10px;
    }
}